<template>
    <div>
        <el-container>
            <el-header height="10%">
                <vxe-form :data="rpt" size="small">
                  <!--  <vxe-form-gather :span=24>
                        <vxe-form-item span="3" title="日期" field="name" :item-render="{}">
                            <vxe-input v-model="rpt.dates" type="date" size="mini" placeholder="日期"></vxe-input>
                        </vxe-form-item>
                        <vxe-form-item span="3" title="~" field="name" :item-render="{}">
                            <vxe-input v-model="rpt.datee" type="date" size="mini" placeholder="日期"></vxe-input>
                        </vxe-form-item>
                    </vxe-form-gather>-->
                    <vxe-form-gather :span=24>
                        <vxe-form-item span="4" title="存货名称" field="存货名称" :item-render="{}">
                            <vxe-input v-model="rpt.inventoryname" size="mini" placeholder="存货名称"></vxe-input>
                        </vxe-form-item>
                       <!-- <vxe-form-item span="4" title="采购订单号" field="采购订单号" :item-render="{}">
                            <vxe-input v-model="rpt.puOrdercode" size="mini" placeholder="采购订单号"></vxe-input>
                        </vxe-form-item>
                        <vxe-form-item span="4" title="供应商" field="供应商" :item-render="{}">
                            <vxe-input v-model="rpt.partnername" size="mini" placeholder="供应商"></vxe-input>
                        </vxe-form-item>-->
                        <vxe-form-item :span="2" field="name" :item-render="{}">
                            <vxe-button size="mini" content="查询" @click="select"></vxe-button>
                        </vxe-form-item>
                        <vxe-form-item :span="2" field="name" :item-render="{}">
                            <vxe-button size="mini" content="导出excel" @click="exportToExcel"></vxe-button>
                        </vxe-form-item>
                    </vxe-form-gather>
                </vxe-form>
            
            </el-header>
            <el-container>
                <div style="width: 100%">
                    <vxe-table :cell-style="rowStyle" 
                                ref="xTable"
                                show-overflow highlight-current-row border show-header-overflow highlight-hover-row size="mini" :data="rs" height="650px" @checkbox-change="selectb" @checkbox-all="selectb">
                        <template v-for="(item,i) in layout">
                            <vxe-table-column :field="item.field" :title="item.title" :width="item.width" :fixed="item.fixed"></vxe-table-column>
                        </template>
                    </vxe-table>
                    <!-- <vxe-pager :align="'left'" background :current-page.sync="params.page.current" :page-size.sync="params.page.size" :total="params.page.total" @page-change="pagechange" :page-sizes=[500,600,1000,10000,100] :layouts="['PrevJump', 'PrevPage', 'JumpNumber', 'NextPage', 'NextJump', 'Sizes', 'FullJump', 'Total']">
                    </vxe-pager> -->
                </div>
                <el-main>
                </el-main>
            </el-container>
        </el-container>
    </div>
</template>
<script>
    import ax from "@/js/ax.js";
    import VXETable from 'vxe-table';
    import VXETablePluginExportXLSX from 'vxe-table-plugin-export-xlsx';
    VXETable.use(VXETablePluginExportXLSX);

    export default {
        name: "bom",
        extends: ax.control(),
        data() {
            return {
                paramssyc: {
                    details: [],
                    classes: [],
                },
                id: "rep2",
                type: "rep",
                layout: null,
                rs: null,
                rpt: {
                    dates: this.getdates(),
                    datee: ax.date.getnow(new Date()),
                    pro: "",
                    inventoryname: "",
                    puOrdercode: "",
                    partnername: "",

                },

            }
        },
        mounted() {
            //console.log(this)
            this.getmenu();
        },
        methods: {
            getdates() {
                var now2 = new Date();
                now2.setMonth(now2.getMonth() - 2);
                return ax.date.getnow(now2)
            },
            getmenu() {
                let _this = this;
                let p = { "type": this.type, "id": this.id }
                ax.base.post("/syc1/getmenu", p).then(data => {
                        _this.layout = JSON.parse(data.data.layout);
                        console.log(_this.layout);
                        _this.rpt.pro = data.data.pro;
                    })
                    .catch(data => {
                        _this.$XModal.message({ message: data.data, status: 'error' })
                    })
            },
            select() {
                let _this = this;
                // let dateFormat = /^(\d{4})-(\d{2})-(\d{2})$/;
                // if (!dateFormat.test(_this.rpt.dates)) {
                //     _this.$XModal.message({ message: '日期格式不正确', status: 'error' })
                //     return;
                // }


                // if (!dateFormat.test(_this.rpt.datee)) {
                //     _this.$XModal.message({ message: '日期格式不正确', status: 'error' })
                //     return;
                // }

                _this.$XModal.message({ message: '开始查询', status: 'success' })
                ax.base.post("/syc1/puexec", _this.rpt).then(data => {
                    //_this.dataset.rs = data.data.records;
                    //_this.params.page.total = data.data.total;
                    _this.rs = data.data;
                    _this.$XModal.message({ message: '查询完成', status: 'success' })
                }).catch(data => {
                    _this.$XModal.message({ message: '查询出错', status: 'error' })
                });
            },
            pagechange({ currentPage, pageSize }) {
                this.params.page.current = currentPage;
                this.params.page.size = pageSize;
                this.select();
            },
            syc() {
                let _this = this;
                ax.base.post("/syc1/sycbom", _this.rpt).then(data => {
                        _this.$XModal.message({ message: data.data, status: 'success' })
                    })
                    .catch(data => {
                        _this.$XModal.message({ message: data.data, status: 'error' })
                    })
            },
            selecta({ records }) {
                this.paramssyc.details = records;
            },
            selectb({ records }) {
                this.paramssyc.classes = records;
            },
             exportToExcel(){
                console.log(this.$refs.xTable);
                this.$refs.xTable.exportData({
                    filename: '多账套库存',
                    sheetName: 'Sheet1',
                    type: 'xlsx'
                })
             }
        }
    }
</script>
<style scoped>
    .el-header,
    .el-footer {
        /* background-color: #b3c0d1; */
        color: #333;
        /* text-align: center; */
        /* line-height: 20px; */
    }

    .el-main {
        background-color: #e9eef3;
        color: #333;
        /* text-align: center; */
        line-height: 400px;
    }

    body>.el-container {
        margin-bottom: 40px;
    }

    .el-container:nth-child(7) .el-aside {
        line-height: 320px;
    }
</style>